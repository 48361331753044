.top-image {
  height: 100%;
  /* background-color: yellow; */
  width: 50vw;
  margin: auto;
  opacity: 1;
}

.blocks-div {
  height: 18.9vh;
  width: 100vw;
  position: relative;
  bottom: 20.736%;
  display: flex;
  align-items: center;
  overflow-x: scroll;
  /* scroll-behavior: smooth; */
  opacity: 1;
}


.block {
  height: 16.667vh;
  min-width: 9.375vw;
  width: 9.375vw;
  margin-left: 0.520vw;
  margin-right: 0.520vw;
  border-radius: 0.625vw;
  opacity: 1;
  overflow: hidden;
}

.block-focus {
  height: 16.667vh;
  min-width: 9.375vw;
  width: 9.375vw;
  margin-left: 0.520vw;
  margin-right: 0.520vw;
  border-radius: 0.625vw;
  opacity: 1;
  overflow: hidden;
  border: 0.3125vw solid #FFFFFF;
}
