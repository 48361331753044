.navitem-mynfts {
    width: 22.22vh;
    height: 5.55vh;
    border-radius: 1.87vw;
    opacity: 1;
    border: none;
    font: normal normal bold 2.22vh/2.685vh Roboto;
    letter-spacing: 0vw;
}

.navitems-focusable-focus {
    background: #F5F5F5 0% 0% no-repeat padding-box;
    color: #000000;
    opacity: 1;
    outline-color: #F5F5F5;
    border: none !important;
}
.navitem-marketplace {
    width: 19.44vh;
    height: 5.55vh;
    border: 0.22vw solid #FFFFFF4D;
    border-radius: 1.56vw;
    opacity: 1;
    font: normal normal bold 2.22vh/2.685vh Roboto;
    letter-spacing: 0.025vw;
}

.not-selected {
    background: #FFFFFF33 0% 0% no-repeat padding-box;
    color: #FFFFFF;
}
.selected {
    background: #FFFFFF99 0% 0% no-repeat padding-box;
    color: #000000;
    border: none;
}