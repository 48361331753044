.menu {
    background: transparent url('../images/Rectangle_black.svg') 0% 0% no-repeat padding-box;
    border-radius: 0.5vw;
    width: 4.166vw;
    height: 7.407vh;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.menu-focus {
    background: transparent url('../images/Rectangle_white.svg') 0% 0% no-repeat padding-box;
    outline: 0;
}

#close:focus {
    /* background-color: red; */
}

.api-type {
    width:fit-content;
    margin: auto;
}

.color-background {
    background-color: red;
}