.header-row {
    height: 20vh;
}

.header-box {
    height: 8.611vh;
    width: 4.84vw;
    margin-left: 3.38vw;
    border: 0.2083vw solid #FFFFFF;
    border-radius: 0.9375vw;
    opacity: 1;
    margin-top: 6.48vh;
}

.header-box-img {
    position: relative;
    height: 100%;
    width: 100%;
    border-radius: 0.8vw;
}

.header-title {
    height: 8.611vh;
    width: 37.96vw;
    margin-left: 1.51vw;
    margin-top: 6.48vh;
    text-align: left;
    font: normal normal bold 1.66vw/1.979vw Roboto;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    display: flex;
    align-items: center;
}

#CN-body {
    height: 76vh;
    overflow-y: scroll;
    /* scroll-behavior: smooth; */
}

.nft-row {
    margin-bottom: 0.92vh;
    margin-left: 0.52vw;
    height:37.07vh;
}

.nft-card {
    width: 13.54vw;
    height: 33.05vh;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    opacity: 1;
    margin-right: 0.833vw;
    margin-left:0.833vw;
    border-radius: 0.625vw;
}

.nft-card-focus {
    width: 15.05vw;
    height: 36.78vh;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    opacity: 1;
    margin-right: 0.078vw;
    margin-left: 0.078vw;
    outline:0;
    border-radius: 0.625vw;
}