.M-card {
    min-width: 33.88vh;
    width: 33.88vh;
    height: 22.77vh;
    margin-right: 3.703vh;
    border-radius: 1.481vh;
}

.M-card-focus {
    min-width: 37.59vh;
    width: 37.59vh;
    height: 25.18vh;
    border: 0.555vh solid #FFFFFF;
    border-radius: 0.933vw 0.933vw 1.3vw 1.3vw;
    opacity: 1;
    outline: 0;
    margin-right:1.848vh;
    margin-left: -1.848vh;
}