.signIn-text-button {
    height: 6.29vh;
    width: 3.54vw;
    /* border-radius: 0.3125vw; */
    /* background-color: #b8b8b8; */
}

.signIn-w-text {
    font: normal normal bold 1.88vw/2.2vw Roboto;
    height: 1.82vw;
    color: white;
    opacity: 1;
    text-align: left;
}

.signedIn {
    font: normal normal 1.88vw/2.2vw Roboto;
    color: white;
    opacity: 1;
    text-align: left;
    font-size: large;
    color: #C9C9C9;
    /* width: 100%; */
}

.signIn-b-text {
    font: normal normal bold 1.35vw/1.66vw Roboto;
    height: 1.82vw;
    color: #C9C9C9;
    opacity: 1;
    letter-spacing: 0px;
    text-align: left;
}

.signIn-title {
    font: normal normal bold 1.97vw/2.395vw Roboto;
    height: 4.25vh;
    color: #F5F5F5;
}

.signIn-close {
    font: normal normal medium 30px/37px Roboto;
    height: 6.20vh;
    width: 10.10vw;
    background: transparent;
    outline: 0;
    letter-spacing: 0px;
    color: #F5F5F5;
    opacity: 1;
}

.signIn-close-focus {
    height: 6.20vh;
    width: 10.10vw;
    background: #F5F5F5 0% 0% no-repeat padding-box;
    border-radius: 0.20vw;
    outline: 0;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}

.signIn-text-button-focus {
    width: 6.16vw;
    height: 9.40vh;
    outline: 0;
}