.mycollections-title {
    width: 13.23vw;
    height: 4.3vh;
    font: normal normal bold 3.51vh/4.25vh Roboto;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 0.7;
}

.signIn-card {
    min-width: 16.67vh;
    width: 16.67vh;
    height: 16.67vh;
    background: #FFFFFF4D 0% 0% no-repeat padding-box;
    border-radius: 0.6vw;
    margin-right: 0.88vh;
}

.signIn-card-focus {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 0.625vw;
    opacity: 1;
    border: white;
    outline: 0;
    height:18.33vh;
    min-width:18.88vh;
    width: 18.88vh;
    margin-right:-0.225vh;
    margin-left:-1.105vh;
}

.signIn-wImg {
    width: 31.11%;
    height: 31.11%;
    background: transparent url('../images/Group_8290.png') 0% 0% no-repeat padding-box;
    opacity: 1;
    background-size: 100% 100%;
}

.signIn-bImg {
    width: 3.95vw;
    height: 7.03vh;
    background: transparent url('../images/Group_8283.svg') 0% 0% no-repeat padding-box;
    opacity: 1;
    background-size: 3.95vw 7.03vh;
}

.play-all-disconnect-card {
    min-width: 16.67vh;
    width: 16.67vh;
    height: 16.67vh;
    border-radius: 0.625vw;
    margin-left: 0.88vh;
    margin-right: 0.88vh;
    background: #FFFFFF4D 0% 0% no-repeat padding-box;
}

.play-all-disconnect-card-focus {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 0.625vw;
    opacity: 1;
    border: white;
    outline: 0;
    height:18.33vh;
    min-width:18.88vh;
    width: 18.88vh;
    margin-right:-0.225vh;
    margin-left:-0.225vh;
}

.playAll-wImg {
    width: 31.11%;
    height: 31.11%;
    background: transparent url('../images/icon_128_Play_56x56.svg') 0% 0% no-repeat padding-box;
    opacity: 1;
    background-size: 100% 100%;
}

.playAll-bImg {
    width: 3.95vw;
    height: 7.03vh;
    background: transparent url('../images/icon_128_Play_76x76.svg') 0% 0% no-repeat padding-box;
    opacity: 1;
    background-size: 3.95vw 7.03vh;
}

.signIn-bText {
    width: 95%;
    height: 2.68vh;
    text-align: center;
    font: normal normal bold 1.25vw/1.51vw Roboto;
    letter-spacing: 0px;
    overflow: scroll;
    color: #000000;
    opacity: 1;
}

.signIn-wText {
    margin-top: 5%;
    width: 85%;
    height: 15%;
    text-align: center;
    font: normal normal normal 2.037vh/2.5vh Roboto;
    letter-spacing: 0px;
    color: #FFFFFF;
    overflow: hidden;
    opacity: 1;
}

.my-nft-card {
    min-width: 16.67vh;
    width: 16.67vh;
    height: 16.67vh;
    border-radius: 0.625vw;
    margin-left: 0.88vh;
    margin-right: 0.88vh;
    overflow: hidden;
    opacity: 1;
}

.my-nft-card-focus {
    min-width: 18.88vh;
    width: 18.88vh;
    height: 18.33vh;
    border-radius: 0.625vw;
    margin-left: -0.225vh;
    margin-right: -0.225vh;
    overflow: hidden;
    opacity: 1;
    border-style: solid;
    border-color: white;
}

.MyCollectionImg {
    position: relative;
    height: 100%;
    width: 100%;
}

.parent-div {
    overflow: hidden;
    white-space: nowrap;
}

.parent-div:focus .text-div{
    animation: scroll-text 10s linear;
    animation-delay: 1s;
}

@keyframes scroll-text {
    0% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(-100%);
    }
}

.text-div {
    display: inline-block;
    white-space: nowrap;
}
