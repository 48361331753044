.my-nfts-font {
    width: 36px;
    height: 36px;
    opacity: 1;
}
/* img
{
background: transparent url('./images/nft_not_available.png') 0% 0% no-repeat padding-box;
} */
.custom-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.custom-left {
    display: flex;
    justify-content: left;
    align-items: center;
}

.custom-favourites-title {
    font: normal normal bold 3.51vh/4.25vh Roboto;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 0.7;
}

.focusable4:focus {
    outline-color: pink;
    border: 10px dotted;
    cursor: pointer;
}

.focusable4 {
    height: 100px;
    width: 100px;
}

.custom-wrapper {
    height: 37.75vh;
    width: 98vw;
    display: flex;
    overflow-x: scroll;
    align-items: center;
    /* scroll-behavior: smooth; */
}

.custom-favourites-card {
    min-width: 24.07vh;
    width: 24.07vh;
    height: 33.05vh;
    background: #d9d6d6 0% 0% no-repeat padding-box;
    margin-right: 2.96vh;
    border-radius: 0.52vw;
    outline: 0;
}

.image {
    position: relative;
    height: 100%;
    width: 100%;
    border-radius: 0.52vw;
}

.MyCollectionImg {
    position: relative;
    height: 100%;
    width: 100%;
}

.favouriteImage {
    position: relative;
    margin: 4%;
    width: 92.30%;
    height: 67.22%;
}

.favourites-focusable-focus {
    min-width: 27.022vh;
    width: 27.022vh;
    height: 36.75vh;
    margin-right: 1.484vh;
    margin-left: -1.476vh;
}

*::-webkit-scrollbar {
    display: none;
}


.MarketPlaceImage {
    position: relative;
    height: 74.4%;
    overflow: hidden;
    border-top-right-radius: 1.3vh;
    border-top-left-radius: 1.3vh;
}

.custom-MarketPlace-card {
    min-width: 19.54vw;
    max-height: 26.05vh;
    text-align: center;
    background: #ffffff 0% 0% no-repeat padding-box;
    margin-right: 0.78vw;
    margin-top: 3.78vw;
    border-radius: 0.52vw;
}

.imageMarket {
    position: absolute;
    border-top-right-radius: 1.481vh;
    border-top-left-radius: 1.481vh;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.textAlignLeft {
    position: relative;
    width: 100%;
    height: 25.60%;
    font: normal normal normal 2.03vh/2.5vh Roboto;
    letter-spacing: 0px;
    color: #000000;
    background: #F5F5F5 0% 0% no-repeat padding-box;
    border-radius: 0px 0px 1.481vh 1.481vh;
    opacity: 1;
}

.textAlignLeftName {
    position: relative;
    height: 5.882%;
    font: normal normal bold 1.481vh/1.75vh Roboto;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    text-align: center;
}

.textAlignLeftFav {
    position: relative;
    width: 92.30%;
    height: 16.806%;
    margin-left:3.85%;
    margin-top: 1.6806%;
    /* background: #0000000D 0% 0% no-repeat padding-box; */
    border-radius: 0.2083vw;
}

.textAlignLeftFav-div1 {
    position: relative;
    height: 50%;
    text-align: left;
    font: normal normal normal 1.29vh/1.57vh Roboto;
    letter-spacing: 0px;
    color: #545454;
    opacity: 1;
    display: flex;
    align-items: center;
    padding: 3%;
}

.favourites-like-icon {
    position: relative;
    width: 13.07%;
    height: 9.52%;
    background: #F5F5F5 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 0.625vw #0000003D;
    opacity: 1;
    border-radius: 50%;
    left: 78.84%;
    top:-90%;
}



/*  */
#M-container {
    height: 72vh;
    overflow-y: scroll;
    /* scroll-behavior: smooth; */
}

.M-wrapper {
    height: 26vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow-x: scroll;
    width: 98vw;
    text-align: center;
    /* scroll-behavior: smooth; */
}

.M-title {
    width: 6.75vh;
    height: 4.25vh;
    text-align: left;
    font: normal normal bold 3.51vh/4.25vh Roboto;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 0.7;
}

.wallet-connect-focus:focus {
    width: 200px;
}

.img-width {
    width:100%;
}

.img-height {
    height:100%;
}

@media only screen and (max-width: 1000px) {
    .margin-top-540p {
        margin-top: 1rem !important;
    }
}

.skeleton-android {
    animation: skeleton-loading 1s linear infinite alternate;
    height: 100%;
    width: 100%;
}


.skeleton-linux{
    /* animation: skeleton-loading 1s linear infinite alternate; */
    height: 100%;
    width: 100%;
}

@keyframes skeleton-loading {
    0% {
        background-color: rgba(255,255,255,0.1);
    }

    100% {
        background-color: rgba(255,255,255,0.2);
    }
}

/* QR Code Dialog Box Changes */
/* wallet connect classes */

/* coinbase classes */
.-cbwsdk-css-reset .-cbwsdk-try-extension.light {
    display: none !important;
}
.-cbwsdk-css-reset .-cbwsdk-connect-content-column-left {
    display: none !important;
}
.-cbwsdk-css-reset .-cbwsdk-connect-content-qr-wrapper {
    width: 630px !important;
}
.-cbwsdk-css-reset .-cbwsdk-cancel-button {
    display: none !important;
}
.-cbwsdk-css-reset .-cbwsdk-connect-content-header {
    margin: 0px 35px 30px !important;
}
.-cbwsdk-connect-content-heading {
    display: none !important;
}