.header-row {
    height: 23.33vh;
}

.header-box {
    height: 8.611vh;
    width: 4.84vw;
    margin-left: 3.38vw;
    border: 0.2083vw solid #FFFFFF;
    border-radius: 0.9375vw;
    opacity: 1;
    margin-top: 6.48vh;
}

.header-title {
    height: 8.611vh;
    width: 37.96vw;
    margin-left: 1.51vw;
    margin-top: 6.48vh;
    text-align: left;
    font: normal normal bold 1.66vw/1.979vw Roboto;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    display: flex;
    align-items: center;
}

.body-card {
    height: 62.96vh;
    width: 35.41vw;
    border-radius: 0.625vw;
    opacity: 1;
    margin-left: 3.38vw;
}

.card-img-div {
    position: relative;
    background-color: #f1d155;
    height: 100%;
    border-radius: 1.5vw;
}

.card-enlarge-div {
    bottom: 13.38%;
    position: relative;
    height: 13.38%;
    background: #F5F5F5 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 0.625vw #0000003D;
    border-radius: 0px 0px 0.625vw 0.625vw;
    font: normal normal bold 1.97vw/2.396vw Roboto;
    letter-spacing: 0px;
    color: #000000;
    z-index: 10;
}

.card-favourite-div {
    bottom:108.528%;
    left:80.5882%;
    position: relative;
    width: 14.70%;
    height: 14.70%;
    background: #F5F5F5 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 0.625vw #0000003D;
    z-index: 10;
    border-radius: 50%;
}

.focused {
    opacity: 1;
    outline: 0;
}

.not-focused {
    opacity: 0.5;
    outline: 0;
}

.txt-1 {
    position: relative;
    top:0.411%;
    font: normal normal bold 1.875vw/2.23vw Roboto;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
}

.txt-2 {
    position: relative;
    top:2%;
    font: normal normal normal 1.25vw/1.51vw Roboto;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 0.7;
}

.txt-3 {
    position: relative;
    top:9%;
    font: normal normal normal 1.718vw/1.51vw Roboto;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
}

.txt-4 {
    position: relative;
    top:13%;
    font: normal normal normal 1.71vw/2.03vw Roboto;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
}

.txt-5 {
    position: relative;
    top:17%;
    font: normal normal normal 1.66vw/1.97vw Roboto;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
}

.txt-6 {
    position: relative;
    top:19%;
    font: normal normal normal 1.25vw/1.51vw Roboto;
    letter-spacing: 0.025vw;
    color: #FFFFFF;
    opacity: 1;
}